// import { Avatar } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircleOverlay, ImageContainer, ProjectImage, StyledTooltip } from './style';
import { INITIAL_PARENT_SIZE } from '../../constants';
import { ProductType } from '../../types/type';
import { transformToHyphenated } from '../../helpers';

interface Circle {
  x: number;
  y: number;
  id?: string;
}

// Extension interface with additional property
interface ExtendedCircle extends Circle {
  url?: string;
  name?: string;
}

interface ImageWithCirclesProps {
  imageUrl: string;
  circles: Circle[];
  products: ProductType[];
  dimension?: ({ width, height }: { width: number, height: number }) => void;
}

const ImageWithCircles: React.FC<ImageWithCirclesProps> = ({
  imageUrl,
  circles,
  products,
  dimension
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgDimension, setImgDimension] = useState({
    height: 0,
    width: 0
  });
  const navigate = useNavigate();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<ExtendedCircle>({
    x: 0,
    y: 0,
  });

  const handleMouseEnter = (circle: ExtendedCircle) => {
    setTooltipVisible(true);
    const prod = (products ?? []).find((p) => p.id === circle.id);
    setTooltipPosition({
      x: circle.x,
      y: circle.y,
      id: circle?.id,
      url: prod?.thumb ?? "",
      name: (prod?.name || prod?.technicalDescription) ?? "",
    });
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleVisitProduct = (id: string) => {
    const product = products.find((product) => product.id === id);
    if (product) {
      const transformedTechnicalDescription = transformToHyphenated(product.technicalDescription as string);
      navigate(`/products/${transformedTechnicalDescription}`, { state: { fromProjects: true } });
    }
  };

  useEffect(() => {
    const observeResize = () => {
      if (imgRef.current) {
        const observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect;
            setImgDimension({
              width,
              height
            });
            dimension && dimension({
              width,
              height
            });
          }
        });
        observer.observe(imgRef.current);
        return () => observer.disconnect();
      }
    };

    observeResize();
  }, []);
  return (
    <ImageContainer>
      {/* Render circles dynamically based on state */}
      <>
        {(circles ?? []).map(
          (circle: Circle, index: React.Key | null | undefined) => (
            <CircleOverlay
              key={index}
              style={{
                top: `${circle.y * (imgDimension.height / INITIAL_PARENT_SIZE.height)}px`,
                left: `${circle.x * (imgDimension.width / INITIAL_PARENT_SIZE.width)}px`,
              }}
              onMouseEnter={() => handleMouseEnter(circle)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleVisitProduct(circle.id as string)}
            />
          )
        )}
      </>
      {tooltipVisible && (
        <StyledTooltip
          className="tooltip"
          onClick={() => handleVisitProduct(tooltipPosition.id as string)}
          style={{
            top: `${tooltipPosition.y * (imgDimension.height / INITIAL_PARENT_SIZE.height)}px`,
            left: `${tooltipPosition.x * (imgDimension.width / INITIAL_PARENT_SIZE.width)}px`
          }}
        >
          {tooltipPosition?.url ? (
            <>
              {/* <Avatar src={tooltipPosition?.url} size={50} shape="square" /> */}
              {/* <p>{tooltipPosition?.name}</p> */}
              <div style={{ paddingLeft: 10, fontSize: 14 }}>{tooltipPosition?.name}</div>
            </>
          ) : (
            "Loading..."
          )}
        </StyledTooltip>
      )}
      <ProjectImage
        ref={imgRef}
        src={imageUrl}
        style={{ objectFit: "cover" }}
        alt="Product"
      />
    </ImageContainer>
  );
};

export default ImageWithCircles;
